import axios, { AxiosError, AxiosInstance } from "axios";

import HTTP_STATUS_CODE from "@models/httpStatusCode";
import {
  getAccessToken,
  getRefreshToken,
  removeAuthTokens,
  setAuthTokens,
} from "@domains/auth/utils/authTokenHandler";
import { NAVIGATION_ROUTE } from "@domains/common/constants/navigationRoute";

const AUTH_BASE_URL = process.env.NEXT_PUBLIC_AUTH_API_BASE_URL;

// NOTE: 토큰 재발급 요청이 여러개 일 경우, 한번만 요청하도록 처리하기 위한 변수
let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (value: unknown) => void;
}[] = [];

// NOTE: 토큰 만료로 실패한 요청들을 queue에 담아두었다가 토큰 재발급 후 처리
const processQueue = (
  error: AxiosError | null,
  token: string | null = null,
) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// NOTE: BaseApi 클래스에 들어가는 interceptors 설정
export function setupInterceptors(instance: AxiosInstance) {
  instance.interceptors.response.use(
    // NOTE: onFulfilled 시 응답 그대로 반환
    (response) => response,
    // NOTE: onRejected 시 에러 처리
    (error) => {
      const originalRequest = error.config;

      // NOTE: 토큰 만료 에러인 경우 처리
      if (
        error.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED &&
        !originalRequest._retry
      ) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return instance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
          const accessToken = getAccessToken();
          const refreshToken = getRefreshToken();

          // NOTE: 토큰 재발급 요청
          axios
            .post(`${AUTH_BASE_URL}/v1/fip/refresh`, {
              accessToken,
              refreshToken,
            })
            .then(({ data }) => {
              // NOTE: 토큰 재발급 성공 시, 새로운 토큰으로 실패했던 요청들 재시도
              const {
                accessToken: newAccessToken,
                refreshToken: newRefreshToken,
              } = data.data;

              setAuthTokens({
                accessToken: newAccessToken,
                refreshToken: newRefreshToken,
              });

              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

              processQueue(null, newAccessToken);

              resolve(instance(originalRequest));
            })
            .catch((err: AxiosError) => {
              // NOTE: 토큰 재발급 실패 시, 로그아웃 처리
              removeAuthTokens();

              processQueue(err, null);

              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      // NOTE: 토큰 만료로 인한 에러가 아닌 경우 에러 그대로 반환
      return Promise.reject(error);
    },
  );
}
