import Head from "next/head";

export const DEFAULT_TITLE =
  "FIP Seoul: Live Seoul Like a Local with International Friends";
export const DEFAULT_DESCRIPTION =
  "Ditch the tours, paint your Seoul adventure! Connect with international friends & explore hidden gems at art crawls, bar nights & cultural events. Your local Seoul crew awaits.";
const DEFAULT_THUMBNAIL = `${process.env.NEXT_PUBLIC_SITE_URL}/meta-tag.png`;

export interface GeneralHeadProps {
  page?: {
    title?: string;
    description?: string;
  };
  openGraph?: {
    title?: string;
    description?: string;
    url?: string;
    image?: string;
  };
}

const GeneralHead = ({ page, openGraph }: GeneralHeadProps) => {
  return (
    <Head>
      {/* Block input zoom-in for ios */}
      <meta
        name="viewport"
        content="width=device-width, viewport-fit=cover, initial-scale=1, maximum-scale=1, user-scalable=0"
      />

      {/* Primary Meta Tags */}
      <title>{page?.title || DEFAULT_TITLE}</title>
      <meta name="title" content={page?.title || DEFAULT_TITLE} />
      <meta
        name="description"
        content={page?.description || DEFAULT_DESCRIPTION}
      />
      <meta name="keywords" content="gathering,party,gathering in seoul" />
      <meta name="theme-color" content="#222222" />

      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={openGraph?.url || process.env.NEXT_PUBLIC_SITE_URL}
      />
      <meta property="og:title" content={openGraph?.title || DEFAULT_TITLE} />
      <meta
        property="og:description"
        content={openGraph?.description || DEFAULT_TITLE}
      />
      <meta
        property="og:image"
        content={openGraph?.image || DEFAULT_THUMBNAIL}
      />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={openGraph?.url || process.env.NEXT_PUBLIC_SITE_URL}
      />
      <meta
        property="twitter:title"
        content={openGraph?.title || DEFAULT_TITLE}
      />
      <meta
        property="twitter:description"
        content={openGraph?.description || DEFAULT_TITLE}
      />
      <meta
        property="twitter:image"
        content={openGraph?.image || DEFAULT_THUMBNAIL}
      />
    </Head>
  );
};

export default GeneralHead;
