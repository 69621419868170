import { GetServerSidePropsContext } from "next";

import { getCookie, setCookie, deleteCookie } from "cookies-next";

import { COOKIE_KEY } from "@logics/utils/cookieHandler";
import { ResponseUserTokenRefreshFip } from "@models/auth/data-contracts";

export type AuthTokenHandlerType = Pick<
  GetServerSidePropsContext,
  "req" | "res"
> & { query?: GetServerSidePropsContext["query"] };

// -------------------------------------------------------------------------------

/* Access Token: api 인가 시 필요한 token */
/* 유효 기간: 30분 */

export function setAccessToken(token: string, context?: AuthTokenHandlerType) {
  setCookie(COOKIE_KEY.ACCESS_TOKEN, token, context);
}

export function getAccessToken(context?: AuthTokenHandlerType) {
  return getCookie(COOKIE_KEY.ACCESS_TOKEN, context);
}

export function removeAccessToken() {
  deleteCookie(COOKIE_KEY.ACCESS_TOKEN);
}

// -------------------------------------------------------------------------------

/* Refresh Token: access token 만료 후 재발급 시 필요한 token */
/* 유효 기간: 7일 */

export function setRefreshToken(token: string, context?: AuthTokenHandlerType) {
  setCookie(COOKIE_KEY.REFRESH_TOKEN, token, context);
}

export function getRefreshToken(context?: AuthTokenHandlerType) {
  return getCookie(COOKIE_KEY.REFRESH_TOKEN, context);
}

export function removeRefreshToken() {
  deleteCookie(COOKIE_KEY.REFRESH_TOKEN);
}

// -------------------------------------------------------------------------------

export function setAuthTokens(
  { accessToken, refreshToken }: ResponseUserTokenRefreshFip,
  context?: AuthTokenHandlerType,
) {
  setAccessToken(accessToken, context);
  setRefreshToken(refreshToken, context);
}

export function removeAuthTokens() {
  removeAccessToken();
  removeRefreshToken();
}
