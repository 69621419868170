import {
  getCookie,
  getCookies,
  setCookie as setCookieNext,
  deleteCookie,
} from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";

export const COOKIE_KEY = {
  ACCESS_TOKEN: "fip.auth.token",
  REFRESH_TOKEN: "fip.refresh.token",
  FIRST_PROFILE_EDITING: "fip.first.profile.editing",
} as const;

type CookieKeyType = (typeof COOKIE_KEY)[keyof typeof COOKIE_KEY];

export function CookieHandler(key: CookieKeyType) {
  const cookieValue = getCookie(key);

  const setCookie = (value: string, options?: OptionsType) => {
    setCookieNext(key, value, options);
  };

  const removeCookie = (options?: OptionsType) => {
    deleteCookie(key, options);
  };

  const removeAllCookies = (options?: OptionsType) => {
    const currentCookies = getCookies();

    Object.keys(currentCookies).forEach((key) => {
      deleteCookie(key, options);
    });
  };

  return {
    cookieValue,
    setCookie,
    removeCookie,
    removeAllCookies,
  };
}

export default CookieHandler;
